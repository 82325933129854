
import {
    ArrowUpIcon,
    ShieldIcon,
    CpuIcon,
    MonitorIcon,
    FeatherIcon,
    EyeIcon,
    UserCheckIcon,
    SmartphoneIcon,
    HeartIcon,
    HelpCircleIcon
} from 'vue-feather-icons';
import {
    Carousel,
    Slide
} from 'vue-carousel';
import countTo from 'vue-count-to';

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";

/**
 * Index-classic-saas component
 */
export default {
    data() {
        return {
            playerVars: {
                autoplay: 1
            }
        }
    },
    components: {
        Navbar,
        Switcher,
        Footer,
        ArrowUpIcon,
        Carousel,
        Slide,
        countTo,
        ShieldIcon,
        CpuIcon,
        MonitorIcon,
        FeatherIcon,
        EyeIcon,
        UserCheckIcon,
        SmartphoneIcon,
        HeartIcon,
        HelpCircleIcon
    }
}
